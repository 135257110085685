<script setup>
import { Link } from '@inertiajs/vue3';
import ArrowRight from '@assets/icons/arrow_right.svg';

const emit = defineEmits(['click']);

defineProps({
    title: {
        type: String,
        required: true,
    },
    productType: {
        type: Object,
    },
    material: {
        type: Object,
    },
    image: {
        type: String,
    },
    url: {
        type: String,
        required: true,
    },
    showMaterialIcon: {
        type: Boolean,
        default: () => false
    }
});

const onClick = () => emit('click');
</script>

<template>
    <Link
        :href="url"
        class="flex flex-row items-center w-full min-h-24 md:min-h-0 sm:gap-2 gap-4 p-4 text-sm rounded-lg bg-base-200/30 card hover:ring-1 hover:ring-inset hover:ring-base-200 group"
        @click="onClick"
    >
        <img v-if="image" :src="image" class="w-5 h-5" :alt="title + ' Icon'" />
        <img v-if="productType?.icon" :src="productType.icon" class="w-5 h-5" :alt="productType.title + ' Icon'" />
        <img v-if="showMaterialIcon && material?.icon" :src="material.icon" class="w-5 h-5" :alt="material.title + ' Icon'" />
        <div class="w-2/3 md:w-[85%] flex max-sm:flex-col flex-wrap gap-x-8 gap-y-1">
            <div class="font-semibold text-primary text-left w-full md:w-64 lg:w-80 md:truncate" :aria-label="title">
                {{ title }}
            </div>
            <div v-if="material" class="w-full md:w-1/2 lg:w-full xl:w-1/2 flex gap-2 sm:gap-8 font-mono">
                <div class="w-full sm:w-1/3 truncate">
                    {{ material?.parent?.title }}<span class="sm:hidden">/{{ material?.title }}</span>
                </div>
                <div class="hidden sm:block w-1/3 sm:w-2/3 truncate">
                    {{ material?.title }}
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end flex-grow">
            <div
                class="flex items-center justify-center w-10 h-10 min-h-0 p-0 rounded-full bg-white text-primary group-hover:text-primary-content group-hover:bg-primary transition-colors"
            >
                <ArrowRight class="relative w-4 h-4" />
            </div>
        </div>
    </Link>
</template>
